import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible.js'
import { Lazyload } from 'vant';
const app = createApp(App)
app.use(router);
import 'video.js/dist/video-js.css';
import {config,yearAll,advertisementList,years,lottery_years} from '@/utils/https/page'
import {nextLottery,getMessageBadge} from '@/utils/https/https';
import component from '@/utils/component.js'// 引入公共组件
var arr = [];
var errorArray = [];
import axios from 'axios';
if (!configUrlBase) {
    configUrlBase = localStorage.getItem('configUrlBase');
    if (configUrlBase == null) {
        configUrlBase = [
            'https://api1.49tkapi8.com:8443',
            'https://api4.49tkapi8.com:8443',
            'https://api2.49tkapi8.com:8443',
            'https://api.49tkapi8.com:8443',
            'https://api3.49tkapi8.com:8443'
        ];
    }
}
const createImage = async () => {
    for(let i =0;i<configUrlBase.length;i++) {
        axios.get(configUrlBase[i]+'/empty.json').then(res=>{
            arr.push(configUrlBase[i])
        }).catch(err=>{
            errorArray.push(configUrlBase[i]);
        })
    }
}
const domains = () => {
    let from = {device:'1',domain:errorArray};
    axios.post(configAddBase.reporting_url+'/api/v1/statistics/domain',from, {headers: {"Content-Type": "multipart/form-data;charset=utf-8"}}).then(res=>{
    
    })
}
createImage();

let mains = ()=>{
 
    document.getElementById('appscc').style.display = "none";
    app.config.globalProperties.$sayHello = () => { 
    // let avList = store.state.advertisementList;
    let avList = JSON.parse(localStorage.getItem('avList'));

    if(localStorage.getItem('lotteryType') == null){
        localStorage.setItem('lotteryType',2);
        localStorage.setItem('lotteryTypes',2);
    }
    if(sessionStorage.getItem('times') == null){
        sessionStorage.setItem('times', Date.now() + 1000 * 60 * 60 *2);
    }

    if(avList==null || avList.tiems < Date.now()){
        advertisementList({type:2}).then(res=>{
            if(res.data.status!=40000){
                let adlist = res.data.data;
                let adlistData = {adlist,tiems: Date.now() + 1000 * 60 * 30 }
                // store.commit('handleAdvertisemen',adlist);     
                localStorage.setItem('avList',JSON.stringify(adlistData))   
            }
        })
    }
    years().then(res=>{
        let c = res.data.data;
        localStorage.setItem('yearColor',JSON.stringify(c))
    })
    lottery_years().then(res=>{
        if(res.data.status == 20000) {
            sessionStorage.setItem("yearList",JSON.stringify(res.data.data))
        }
    })
    nextLottery().then(res=>{//获取下一期的开奖数据
        if(res.status!=500){
            let datas = res.data.data;
            localStorage.setItem('nextLottery',JSON.stringify(datas))
        }
    })
    // if( localStorage.getItem('yearone')==null){
        yearAll().then(res=>{   
            if(res==undefined) return false
            let data = res.data.data;
            let reversed = data.reverse();
            localStorage.setItem('year', JSON.stringify(reversed));
        })
    // }
    config().then(res=>{//获取公共配置
        if(res==undefined) return false
            let datas = res.data.data;
            localStorage.setItem('config',JSON.stringify(datas));
            store.commit('handleConfing',datas);    
            document.title = '77图库'
            document.title = datas.name
            var head = document.getElementsByTagName('head')[0];
            // 获取当前 favicon 元素
            var favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
            favicon.type = 'image/x-icon';
            favicon.rel = 'shortcut icon';

            // 设置新的 favicon 地址
            favicon.href =  datas.host +datas.logo

            // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
            if (!document.querySelector("link[rel*='icon']")) {
                head.appendChild(favicon);
        }
    })

    }
    // localStorage.setItem('lotteryType',5)
    app.config.globalProperties.$filters = {
        format(value) {

            if (value>=10000) {
                let num = value/10000
                return num.toFixed(1) + '万'
            
            } else {
                return value
            }
        }
    }

    // 注册时设置`lazyComponent`选项
    app.use(Lazyload, {
    lazyComponent: true,
    });
    app.use(store);
    // app.use(loading);
    app.use(component);

    app.mount('#app');
}

const date = new Date();
const day = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
var toDays = localStorage.getItem('ping');
let timer=null;
let count=0;
if (day == toDays) {
  configUrlBase = JSON.parse(localStorage.getItem('configUrlBase'));
  mains();
} else {
    timer=setInterval(()=>{
        if(arr.length != 0){
            clearInterval(timer)
            localStorage.setItem('configUrlBase',JSON.stringify(arr))
            configUrlBase = arr;
            mains();
            localStorage.setItem('ping',day)
        }else{
            if(count>=5){
                clearInterval(timer)
                document.getElementById('tipss').innerHTML = "暂无可用路线，请更换网络或联系客服";
                document.getElementById('kfs').style.display = "block";
            }
        }
        count++
    },2000)
    setTimeout(()=>{
        if(errorArray.length !=0){
            domains()
        }
    },15000)
}

