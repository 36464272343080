import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-77365d42"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-guess"
};
const _hoisted_2 = {
  class: "tk-lootery"
};
const _hoisted_3 = {
  class: "vote-record"
};
const _hoisted_4 = {
  class: "vote-sx"
};
const _hoisted_5 = {
  class: "tk-circle"
};
const _hoisted_6 = {
  class: "bfb"
};
const _hoisted_7 = {
  class: "vot-num"
};
import lottery from '@/components/page/lottery.vue';
import year from '@/components/page/guess/year.vue';
import Dialog from '@/components/mode/message';
import { guess, guessDetail, guessVote } from '@/utils/https/guess';
import { numbers } from '@/utils/https/lottery';

import { useRouter, useRoute } from 'vue-router';
import { onBeforeMount, reactive, toRefs } from 'vue';
export default {
  __name: 'VotRecord',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      guessissue(data.year, data.lottery);
    });
    let data = reactive({
      issue: [],
      year: localStorage.getItem('yearone'),
      //年份
      lottery: localStorage.getItem('lotteryType'),
      //彩票类型
      galleryData: [],
      voteList: [],
      more: '',
      //更多
      numarray: {},
      oneissue: '',
      guessId: ''
    });
    let {
      activeType,
      issue,
      galleryData,
      voteList,
      shows,
      showVideo,
      show,
      numarray,
      oneissue
    } = toRefs(data);
    //接口请求地址
    let guessissue = async (year, lottery) => {
      //获取期数
      let guessId;
      await guess({
        year: year,
        lotteryType: lottery
      }).then(res => {
        if (res == undefined) return false;
        data.issue = res.data.data;
        guessId = res.data.data[0].id;
      });
      if (data.guessId == '') {
        guessDetails(guessId);
      } else {
        guessDetails(data.guessId);
      }
    };
    let guessDetails = id => {
      //获取详情
      guessDetail({
        id: id
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.HumorousDetailData;
        number(datas.year, datas.lotteryType, datas.issue); //详情获取每一期的期数 
        data.voteList = res.data.data.voteList;
        data.voteList.forEach(item => {
          let c = sum(data.voteList);
          if (c == 0) {
            item.total = 0 + '%';
          } else {
            item.total = 100 / c * item.vote_num + '%';
          }
        });
      });
    };
    let guessVotes = (id, sx) => {
      guessVote({
        id: id,
        vote_zodiac: sx
      }).then(res => {
        Dialog(res.data.message, '#3f2860');
        if (res.data.message != '您已经投票了') {
          data.voteList.forEach(item => {
            if (item.name == sx) {
              item.vote_num += 1;
            }
            let c = sum(data.voteList);
            if (c == 0) {
              item.percentage = 0;
            } else {
              item.percentage = 100 / c * item.vote_num;
            }
          });
        }
      });
    };
    function sum(arr) {
      //获得投票总数
      var s = 0;
      arr.forEach(function (val) {
        s += val.vote_num;
      }, 0);
      return s;
    }
    ;
    let number = (year, lotteryType, issue) => {
      //每一期开奖结果
      numbers({
        year: year,
        lotteryType: lotteryType,
        issue: issue
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        data.numarray = datas;
      });
    };
    //功能区域
    let majoissue = () => {
      //显示更多期数
      router.push({
        path: '/guess/period',
        query: {
          data: JSON.stringify(data.issue)
        }
      });
    };
    let issueFun = item => {
      //切换期数
      data.galleryData = [];
      data.oneissue = item.issue;
      guessDetails(item.guessId);
    };
    let majoYear = item => {
      data.year = item;
      data.more = '';
      data.oneissue == '';
      data.galleryData = [];
      guessissue(item, 1);
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "投票记录"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(year, {
        issue: _unref(issue),
        onMajoissue: _unref(majoissue),
        onIssueFun: _unref(issueFun),
        onMajoYear: _unref(majoYear),
        onissue: _unref(oneissue),
        year: _unref(data).year
      }, null, 8, ["issue", "onMajoissue", "onIssueFun", "onMajoYear", "onissue", "year"]), _createElementVNode("div", _hoisted_2, [_createVNode(lottery, {
        types: false,
        data: _unref(numarray)
      }, null, 8, ["data"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(voteList), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("span", _hoisted_4, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
          class: "circle",
          style: _normalizeStyle({
            'width': item.percentage + '%'
          })
        }, null, 4), _createElementVNode("div", _hoisted_6, _toDisplayString(item.percentage + '%'), 1)]), _createElementVNode("span", _hoisted_7, _toDisplayString(item.vote_num) + "票", 1)]);
      }), 128))])])])], 64);
    };
  }
};